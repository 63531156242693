import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "button" ]

  toggle() {
  	var details = $("#sftp_details")
  	details.toggleClass("block")
  	details.toggleClass("hidden")
  	//console.log("Hello!")
  }
}